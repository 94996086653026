@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap');

html{
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 28px;
}
div#root {
  overflow: hidden;
}
body {
 background: #1A0621;  /* fallback for old browsers */
background: -webkit-linear-gradient( #0D0311, #1A0621);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(#0D0311, #1A0621); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
font-family: 'Archivo Narrow', sans-serif;
margin: 0;
padding: 0;
}
.home {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.splash-heading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
h1 {
  color: #F3F3F3;
  font-size: 4em;
  text-transform: uppercase;
  margin: 0px;
  font-weight: 500;
}
h1 .Bold {
  font-weight: 700;
}
.Highlight {
  color: #00bd9d;
}
p {
  color: #F3F3F3;
  margin: 0px;
  font-size: 1.1em;
  font-weight: 400;
}
p .Bold {
  font-weight: 600;
}
.social-links ul {
  display: flex;
  list-style: none;
  text-overflow: 400;
  font-size:1em;
}
.social-links li  {
  margin: 4px;
}
.main-links{
  text-decoration: none;
  color: #00bd9d;
  transition: 0.2s;
}
.main-links:hover, .main-links a:hover{
  color: #F3F3F3;
}
.social-links .icon {
  margin: 0px 5px 0px 10px;
}
@media only screen and (max-width: 766px) {
  h1 {
    font-size: 1.2em;
}
p {
  font-size: 1.5em;
  word-break: break-word;
  padding: 10%;
}
.social-links {
  width: 100%;
}
}